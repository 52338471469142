@use "@/styles/mixins" as mixins;
@use "@/styles/utilities" as utilities;

.trip_route {
  .route-info {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .route-duration {
      display: flex;
      flex-direction: row;
      gap: 8px;

      & > svg,
      img {
        width: 20px;
        height: 20px;
      }

      span {
        font-size: 16px;
        line-height: 20px;

        & > span {
          font-weight: 700;
        }
      }
    }
  }

  .route-places {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    @include mixins.clamp(margin-top, 24px, 32px);
    @include mixins.clamp(padding-top, 24px, 32px);
    border-top: 1px solid var(--text-hint);

    &.with-map {
      :global(.step-list) {
        width: 100%;
        max-width: 32%;
      }
    }

    .place-view {
      position: relative;
      width: 100%;
      max-width: 68%;
      height: 100%;
      border-radius: 12px;
      aspect-ratio: 16/9;
      background-color: var(--text-hint);

      :global(.google-map),
      :global(.swiper-container--gallery),
      :global(.swiper-container--gallery .swiper),
      :global(.swiper-container--gallery .swiper img) {
        overflow: hidden;
        border-radius: 12px;
      }

      :global(.google-map),
      :global(.swiper-container--gallery),
      :global(.swiper-container--gallery .swiper img) {
        height: 100%;
        width: 100%;
      }

      :global(.swiper-container--gallery .swiper img) {
        object-fit: cover;
        object-position: center;
      }

      :global(.swiper-container--gallery) {
        position: relative;
        display: flex;
        align-items: center;

        :global(.swiper-nav) {
          width: 40px;
          height: 40px;
          transform: unset;

          &:global(.swiper-nav--prev) {
            left: 24px;
          }

          &:global(.swiper-nav--next) {
            right: 24px;
          }
        }
      }

      :global(.google-map) {
        display: flex;
      }
    }

    :global(.swiper-container) {
      max-width: 800px;

      :global(.swiper-slide) {
        height: 100%;
      }
    }
  }

  :global(.swiper-tabs + .swiper-container) {
    margin-top: 20px;
  }

  :global(.toggle-view) {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 2;
  }

  :global(.step-list) {
    @include mixins.max-sm {
      flex-direction: row;
      white-space: nowrap;
      overflow-x: scroll;
      padding: 2px;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      scroll-padding-left: 2px;

      :global(.step-list__item) {
        padding: 12px;
        border-radius: 12px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.06),
          0px 2px 4px 0px rgba(0, 0, 0, 0.04);
        scroll-snap-align: start;

        :global(.step-list__item-tail) {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .trip_route {
    & > :global(.swiper.swiper-tabs.swiper-tabs--rounded) {
      @include mixins.clamp(margin-right, -20px, -50px);
      @include mixins.clamp(margin-left, -20px, -50px);
      overflow: hidden;

      &,
      .route-info,
      :global(.step-list),
      .place-view {
        @include mixins.clamp(padding-right, 20px, 40px);
        @include mixins.clamp(padding-left, 20px, 40px);
      }

      .place-view {
        background-clip: content-box;
      }
    }

    .route-places {
      flex-direction: column-reverse;

      &.with-map :global(.step-list),
      .place-view {
        width: 100%;
        max-width: unset;
      }

      .place-view {
        aspect-ratio: 1 / 0.883;

        :global(.swiper-container--gallery) {
          :global(.swiper-nav) {
            display: flex;

            &:global(.swiper-nav--prev) {
              left: 16px;
            }

            &:global(.swiper-nav--next) {
              right: 16px;
            }
          }
        }
      }
    }
  }
}
