@use "@/styles/mixins" as mixins;

.youtube {
  &__video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 12px;
    }
  }

  &__text {
    column-count: 2;
    @include mixins.clamp(column-gap, 20px, 40px);
    @include mixins.clamp(margin-top, 24px, 40px);
  }

  &--row {
    display: flex;
    flex-direction: row;
    @include mixins.clamp(gap, 20px, 40px);

    .youtube__video,
    .youtube__text {
      flex-basis: 50%;
    }

    .youtube__video {
      height: inherit;
      @include mixins.clamp(padding, 16px, 32px);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04),
        0px 0px 4px rgba(0, 0, 0, 0.06);
      border-radius: 12px;
      aspect-ratio: 16/9;

      iframe {
        position: relative;
      }
    }

    .youtube__text {
      column-count: unset;
      column-gap: unset;
      margin-top: unset;
    }
  }
}

@media screen and (max-width: 992px) {
  .youtube--row {
    flex-direction: column;

    .youtube__video {
      padding-bottom: 56.25%;

      iframe {
        position: absolute;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .youtube__text {
    column-count: 1;
  }
}
